<template>
  <tableau view="Capacityutilization_v2/Capacityutilizationmonth"/>
</template>

<script>
import Tableau from "./Tableau"

export default {
  components: { Tableau }
}
</script>
