<template>
  <iframe v-if="ticket"
      width="100%" height="100%"
      :src="`https://tableau.chaika.com/trusted/${ticket}/views/${view}?:embed=y&:display_count=n&:showAppBanner=false&:origin=viz_share_link&:showVizHome=n${queryParams}`"
      allowfullscreen="true"
  >
  </iframe>
  <span v-else>Создание тикета</span>
</template>

<script>
import bi from '../../services/bi'
import { mapGetters } from "vuex"

export default {
  props: {
    view: { type: String, required: true },
    injectDoctorName: { type: Boolean, default: false },
    doctorParamName: { type: String, default: 'Доктор' },
    userParam: { type: String, default: 'userId' },
    injectUserId: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(['currentUser']),
    queryParams() {
      let params = '';
      if (this.injectDoctorName) {
        params += `&${this.doctorParamName}=${this.currentUser.fullName}`
      }
      if (this.injectUserId) {
        params += `&${this.userParam}=${this.currentUser.externalId}`
      }
      return params;
    }
  },
  data() {
    return {
      ticket: null
    }
  },
  async created() {
    const resp = await bi.tableauTicket()
    this.ticket = resp.body.ticket
  }
}
</script>
